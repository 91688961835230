import { Typography, Box } from '@mui/material'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
import { Navigation, Pagination, EffectCoverflow, Autoplay, Scrollbar, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './productServed.css'

import container_liners from '../../assets/container_liners.png'
import small_bags from '../../assets/small_bags.png'
import tarpaulin from '../../assets/tarpaulin.png'

const product_list = [
    {
        label: 'Container Liner',
        imgPath: container_liners
    },
    {
        label: 'Small Bags (Woven Sacks)',
        imgPath: small_bags
    },
    {
        label: 'Tarpaulin',
        imgPath: tarpaulin
    }

]

function ProductsServed_2 (props) {

    return (
        <Box mt='2rem' mb='3rem'>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0rem 1.2rem',textAlign:'center' }}>
                <Typography variant='h4' sx={{ mb: '1.2rem', mt: '0.5rem',color:'primary.main' }}>{props.title}</Typography>
            </Box>
            <Box >
                <Swiper
                    style={{ paddingBottom:'1rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    // loop={true}
                    slidesPerView={'auto'}
                    autoplay={true}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: false,

                    }}
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    navigation
                    modules={[EffectCoverflow, Pagination, Navigation, Autoplay, Scrollbar, A11y]}
                // className="swiper_container"
                >
                    {
                        product_list.map((imageLink, index) => {
                            // console.log(imageLink.imgPath);
                            return(
                            < SwiperSlide >
                                <img src= {imageLink.imgPath} alt={imageLink.label} />
                                <Box sx={{textAlign:'center',paddingBottom:'0.5rem',color:'#284C6D'}}>
                                    <Typography variant='h5'>{imageLink.label}</Typography>
                                    {/* <Typography variant='body1'>Protect your containers & streamline disposal with our durable Container Liners.</Typography> */}
                                </Box>
                            </SwiperSlide>)
                        })
                    }

                    {/* <div className="slider-controler">
                        <div className="swiper-button-prev slider-arrow">
                            <AiOutlineArrowLeft style={{color:'white', fontSize:'1.5rem'}}/>
                        </div>
                        <div className="swiper-button-next slider-arrow">
                            <AiOutlineArrowRight style={{color:'white', fontSize:'1.5rem'}} />
                        </div>
                        <div className="swiper-pagination"></div>
                    </div> */}
                </Swiper>
            </Box>
        </Box >
    )
}

export default ProductsServed_2