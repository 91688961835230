import React from 'react'
import { useTheme } from "@mui/material/styles";
import { Box, Typography, useMediaQuery } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import banner_1 from '../assets/newbanner_01.png'
import banner_2 from '../assets/newbanner_02.png'
import banner_3 from '../assets/newbanner_03.png'
import banner_4 from '../assets/newbanner_04.png'
import banner_5 from '../assets/newbanner_05.png'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "Kelwa Polymers",
    imgPath: banner_1
  },
  {
    label: "Kelwa Polymers",
    imgPath: banner_2
  },
  {
    label: "Kelwa Polymers",
    imgPath: banner_3
  },
  {
    label: "Kelwa Polymers",
    imgPath: banner_4
  },
  {
    label: "Kelwa Polymers",
    imgPath: banner_5
  }
];



function Carousel() {

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;
  const isMobile = useMediaQuery('(max-width:950px)');

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div>
      {
        !isMobile && (
          <Box sx={{ flexGrow: 1, height: '80vh' }}>
            {/* <Paper
          square
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            height: 50,
            pl: 2,
            bgcolor: "background.default"
          }}
        >
          <Typography >{images[activeStep].label}</Typography>
        </Paper> */}
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              { // carousel for mobile 
                images.map((step, index) => (
                  <div key={step.label}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                          component="img"
                          sx={{
                            height: '80vh',
                            display: "block",
                            maxWidth: '100%',
                            overflow: "hidden",
                            width: "100%",
                            objectFit:'cover'
                          }}
                          src={step.imgPath}
                          alt={step.label}
                        />
                        {/* <Typography variant="h3" sx={{position:'absolute', top:'45%', backgroundColor: 'primary.main', color:'secondary.main' , width:'auto', whiteSpace:'nowrap'}}>Kelwa Polymers</Typography> */}
                      </Box>
                    ) : null}
                  </div>
                )
                )
              }

            </AutoPlaySwipeableViews>
            <MobileStepper
              sx={{ position: 'relative', bottom: '5%', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0)' }}
              steps={maxSteps}
              activeStep={activeStep}
            />
            {/* <MobileStepper sx={{backgroundColor:'rgba(255,255,255,0.3)'}}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      /> */}
          </Box>
        )
      }
      {/* IS MOBILE */}
      {
        isMobile && (
          <Box sx={{ flexGrow: 1, height: 'auto' }}>
            {/* <Paper
          square
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            height: 50,
            pl: 2,
            bgcolor: "background.default"
          }}
        >
          <Typography >{images[activeStep].label}</Typography>
        </Paper> */}
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              { // carousel for mobile 
                images.map((step, index) => (
                  <div key={step.label}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                          component="img"
                          sx={{
                            height: 'auto',
                            display: "block",
                            maxWidth: '100%',
                            overflow: "hidden",
                            width: "100%",
                            objectFit:"cover"
                          }}
                          src={step.imgPath}
                          alt={step.label}
                        />
                        {/* <Typography variant="h1" sx={{position:'absolute', top:'45%' ,color:'black' , backgroundColor:'rgba(255,255,255,0.7)', width:'65%'}}>{step.label}</Typography> */}
                      </Box>
                    ) : null}
                  </div>
                )
                )
              }

            </AutoPlaySwipeableViews>
            <MobileStepper
              sx={{ position: 'relative', bottom: '5%', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0)' }}
              steps={maxSteps}
              activeStep={activeStep}
            />
            {/* <MobileStepper sx={{backgroundColor:'rgba(255,255,255,0.3)'}}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      /> */}
          </Box>
        )
      }

    </div>
  );
}

export default Carousel