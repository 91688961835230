import React, { useState } from 'react'
import { Grid, Box, Typography, Button } from '@mui/material'

function openBusinessPresentation() {
  window.open(
    "https://drive.google.com/file/d/1pj8Dok62HoeloPmyBo55fNFmusGu7YR2/view?usp=sharing", "_blank");
}

// const [isTruncated, setIsTruncated] = useState(true);
// const resultString = isTruncated ? text.slice(0, 100) : text;

function InfoSection() {
  return (
    <div>
      <Grid container >
        <Grid item sm={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0rem 1.2rem' }}>
          <Box>

            <Typography variant='h4' sx={{ mb: '1.2rem', mt: '0.5rem',color:'primary.main' }}>About Us</Typography>
            <Typography variant="body1" sx={{textAlign:'justify'}}>
            Welcome to Kelwa Polymers, your premier destination for premium Polypropylene/PP Woven Bags, Container Liners, and Flexible Intermediate Bulk Container (FIBC) Bags. Nestled in the heart of Mhow, Indore, India, our state-of-the-art in-house production facility boasts a formidable capacity of 3000 MT per annum, ensuring unmatched efficiency and reliability.
            <br/>
            At Kelwa Polymers, we adhere to a simple yet profound philosophy: "Quality is Our Belief." This ethos drives us to deliver excellence with every order, committing to customer satisfaction beyond measure. Our dedication knows no bounds; we give 1000% to every project, ensuring unparalleled results that exceed expectations. Specializing in providing packaging solutions, we cater to a diverse array of industries including food, agriculture, construction, logistics, and chemicals. Whether it's safeguarding agricultural produce, enhancing construction logistics, or facilitating the safe transport of chemical goods, Kelwa Polymers stands as the trusted partner for all your packaging needs.
            <br/>
            With a relentless pursuit of innovation, uncompromising quality standards, and a customer-centric approach, Kelwa Polymers redefines excellence in the realm of packaging solutions. Experience the difference with Kelwa Polymers, where quality isn't just a standard—it's our unwavering commitment to your success.
            </Typography>
            <Button onClick={() => openBusinessPresentation()} sx={{ my: '1.5rem' }} variant="contained"  ><Typography variant="h6" sx={{ textTransform: 'none' }}  >Company Brochure</Typography></Button>
          </Box>
        </Grid>
        <Grid item sm={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'primary.main' }}>
          <img alt="machine" style={{ width: '85%', height: '85%' }} src="https://comsyn.com/wp-content/themes/comsyn/images/ab1.png" />
        </Grid>

      </Grid>
    </div>
  )
}

export default InfoSection