import { Typography } from '@mui/material';
import React from 'react'
import './spinner.css';
function Spinner() {
    return (
        <div style={{backgroundColor:'rgba(29, 44, 77,1)' , width:'100%', height:'100vh', display:'flex', flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <Typography variant="h4" sx={{color:'secondary.main'}}>Kelwa Polymers</Typography>
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
    )
}       

export default Spinner