import { createTheme } from "@mui/material";
// import '../eczar_font.css'
const CustomTheme = createTheme({
    palette: {
        primary: {
            main: '#284c6d'
            // 'rgba(29, 44, 77,1)'
        },
        secondary: {
            main: '#fff',

        },
        text:{
            secondary: '#fff'
        }
    },
    typography: {
        fontFamily: 'Eczar',
        // fontFamily: 'Roboto',
        fontWeight:'bold'
    },
});
export default CustomTheme;