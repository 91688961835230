import React, { useState } from 'react'
import { AppBar, Button, Box, Drawer, Tabs, Tab, Toolbar, Typography, useMediaQuery, IconButton, Menu, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { BiMenuAltLeft } from 'react-icons/bi';
import { useNavigate } from "react-router-dom";
import logo from '../assets/logo_1.png';

function Navbar() {
    const [value, setValue] = useState('home');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const isMobile = useMediaQuery('(max-width:750px)');

    function handleMenuOpen(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    const [state, setState] = React.useState({
        top: false,
    });

    function openBusinessPresentation(){
        window.open(
            "https://www.geeksforgeeks.org", "_blank");
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List sx={{backgroundColor: 'secondary.main' , color:'primary.main'}}>
                {/* {["Home", "About Us", "Products", "Gallery", "Contact Us"].map((text, index) => ( */}
                    <ListItem sx={{flexDirection:'column'}} disablePadding>
                        <ListItemButton onClick={()=>{navigate('/')}}>
                            <Typography variant="h6">Home</Typography>
                        </ListItemButton>
                        <ListItemButton onClick={()=> openBusinessPresentation()}>
                            <Typography variant="h6">Business Presentation</Typography>
                        </ListItemButton>
                        <ListItemButton onClick={()=>{navigate('/about')}}>
                            <Typography variant="h6">About Us</Typography>
                        </ListItemButton>
                        <ListItemButton onClick={()=>{navigate('/products')}}>
                            <Typography variant="h6">Products</Typography>
                        </ListItemButton>
                        <ListItemButton onClick={()=>{navigate('/gallery')}}>
                            <Typography variant="h6">Gallery</Typography>
                        </ListItemButton>
                        <ListItemButton onClick={()=>{navigate('/contact')}}>
                            <Typography variant="h6">Contact Us</Typography>
                        </ListItemButton>
                    </ListItem>
                {/* ))} */}
            </List>
        </Box>
    );

    const navigate = useNavigate();

    return (
        <AppBar position="static" sx={{backgroundColor: 'secondary.main' , color:'primary.main'}}>
            <Toolbar sx={{ justifyContent: "space-between" }}>
                <a href={'/'} style={{textDecoration:'none'}} >
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <img style={{width:'3rem'}} src={logo} alt="logo"/>
                        <Box> 
                        <Typography variant="h5" sx={{ color: 'primary.main',ml:'0.5rem',fontWeight:'800' }}>KELWA POLYMERS</Typography>
                        <Typography variant="body2" sx={{ color: '#6D6D6D',ml:'0.5rem',fontWeight:'800' }}>QUALITY IS OUR BELIEF</Typography>
                        </Box>
                    </Box>
                </a>
                {/* Render a Menu component if on a mobile device */}
                {/* {isMobile && (
                    <div anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                        {["top"].map((anchor) => (
                            <React.Fragment key={anchor}>
                                <IconButton color="inherit" onClick={handleMenuOpen, toggleDrawer(anchor, true)}>
                                    <BiMenuAltLeft />
                                </IconButton>
                                <Drawer
                                    anchor={anchor}
                                    open={state[anchor]}
                                    onClose={toggleDrawer(anchor, false)}
                                >
                                    {list(anchor)}
                                </Drawer>
                            </React.Fragment>
                        ))}
                    </div>
                )} */}
                {/* Render individual buttons if not on a mobile device */}
                {/* {
                    !isMobile && (
                        <Tabs value={value} onChange={handleChange} textColor= 'secondary.main' indicatorColor= 'primary' >
                            <Tab sx={{textTransform:'none', fontSize:'1rem'}} value="home" label="Home" onClick= { ()=> navigate('/') } />
                            <Tab sx={{textTransform:'none', fontSize:'1rem'}} label="Business Presentaion" onClick= {()=> openBusinessPresentation()} />
                            <Tab sx={{textTransform:'none', fontSize:'1rem'}} value="aboutUs" label="About Us" onClick= { ()=> navigate('/about') } />
                            <Tab sx={{textTransform:'none', fontSize:'1rem'}} value='products' label="Products" onClick= { ()=> navigate('/products') }/>
                            <Tab sx={{textTransform:'none', fontSize:'1rem'}} value='gallery' label="Gallery" onClick= { ()=> navigate('/gallery') } />
                            <Tab sx={{textTransform:'none', fontSize:'1rem'}} value='contactUs' label="Contact Us" onClick= { ()=> navigate('/contact') }/>
                        </Tabs>
                    )
                } */}

            </Toolbar>
        </AppBar>
    )
}

export default Navbar