import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Slider() {
    return (
        <div>
            <Carousel className="adjustHeight" showArrows={true} autoPlay={true} infiniteLoop={true} showThumbs={false}>
                <div>
                    <img src="https://media.istockphoto.com/id/1086775280/photo/from-polypropylene-manufacture-of-details.jpg?s=612x612&w=0&k=20&c=Q4uAZmLTBFAYJU6VyJslIpSdgkvoLLr9aFcfinMO2Ek=" />
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img src="https://media.istockphoto.com/id/1086775280/photo/from-polypropylene-manufacture-of-details.jpg?s=612x612&w=0&k=20&c=Q4uAZmLTBFAYJU6VyJslIpSdgkvoLLr9aFcfinMO2Ek=" />
                    <p className="legend">Legend 2</p>
                </div>
            </Carousel>
        </div>
    )
}

export default Slider