    import React from 'react'
    import { Box, List, ListItem, Typography,Grid,Divider } from '@mui/material'
    import banner_1 from '../../assets/newbanner_01.png'

    function WhyKelwa() {
    return (
        <div>
            <Divider variant="middle" sx={{borderRadius:'0.5rem', borderBottomWidth: 5, borderColor:'#6d6d6d', margin:'0.7rem 1rem'}} />
            {/* <Box sx={{display: 'flex', flexDirection:'column', padding: '0rem 1.2rem'}}>
            <Typography variant='h4' sx={{ mb: '0.5rem', mt: '1rem', textAlign:'center' }}>Why Kelwa Polymers ?</Typography>
            
            <Typography variant='h6'>5 Reasons to Choose Kelwa Polymers for Packaging Excellence:</Typography>
            <List>
                <ListItem>
                1. Unmatched Quality & Reliability: State-of-the-art facility & "Quality is Our Belief" philosophy guarantee top-notch products and efficient service.
                </ListItem>
                <ListItem>
                2. Diverse Solutions: From food-grade bags to FIBCs, we cater to various industries like agriculture, construction, and chemicals.
                </ListItem>
                <ListItem>
                3. Innovation & Customization: We continuously innovate and offer custom solutions to perfectly match your specific packaging needs.
                </ListItem>
                <ListItem>
                4. Customer Focus: We go the extra mile to ensure your satisfaction with a commitment to exceeding expectations.
                </ListItem>
                <ListItem>
                5. Sustainable Practices: We prioritize eco-friendly production with energy-saving practices and recycled materials.
                </ListItem>
            </List>
            <Typography variant='h6'>Experience the Kelwa Polymers difference - choose quality, innovation, and a partner dedicated to your success!</Typography>
            </Box> */}

            <Box>
            <Grid container >
            <Grid item sm={12} md={6} style={{paddingLeft:'1.2rem', paddingRight:'0.5rem',alignItems:'center',display:'flex'}}>
            <Box>

            <Typography variant='h4' sx={{ mb: '0.5rem', mt: '0.2rem', color:'primary.main'}}>Why Kelwa Polymers?</Typography>
            
            <Typography variant='h6' sx={{textAlign:'justify'}}>5 Reasons to Choose Kelwa Polymers for Packaging Excellence:</Typography>
            <List sx={{textAlign:'justify'}}>
                <ListItem sx={{textAlign:'justify'}}>
                1. Unmatched Quality & Reliability: State-of-the-art facility & "Quality is Our Belief" philosophy guarantee top-notch products and efficient service.
                </ListItem>
                <ListItem sx={{textAlign:'justify'}}>
                2. Diverse Solutions: From food-grade bags to FIBCs, we cater to various industries like agriculture, construction, and chemicals.
                </ListItem>
                <ListItem sx={{textAlign:'justify'}}>
                3. Innovation & Customization: We continuously innovate and offer custom solutions to perfectly match your specific packaging needs.
                </ListItem>
                <ListItem sx={{textAlign:'justify'}}>
                4. Customer Focus: We go the extra mile to ensure your satisfaction with a commitment to exceeding expectations.
                </ListItem>
                <ListItem sx={{textAlign:'justify'}}>
                5. Sustainable Practices: We prioritize eco-friendly production with energy-saving practices and recycled materials.
                </ListItem>
            </List>
            <Typography variant='h6' sx={{textAlign:'justify'}}>Experience the Kelwa Polymers difference - choose quality, innovation, and a partner dedicated to your success!</Typography>
                {/* <Button onClick={() => openBusinessPresentation()} sx={{ my: '1.5rem' }} variant="contained"  ><Typography variant="h6" sx={{ textTransform: 'none' }}  >Business Presentation</Typography></Button> */}
            </Box>
            </Grid>
            <Grid item sm={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "primary.main" }}>
            <img alt="machine" style={{ width: '85%', height: '100%' }} src= {banner_1} />
            </Grid>

        </Grid>
            </Box>
        </div>
    )
    }

    export default WhyKelwa