import React, { useEffect, useState } from 'react'
import Carousel from '../Carousel'
import InfoSection from '../homeComp/InfoSection'
import Slider from '../../utils/Slider';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ProductsServed from '../homeComp/ProductsServed';
import WhyKelwa from '../homeComp/WhyKelwa';
import InHouseProduction from '../homeComp/InHouseProduction';
import { Divider } from '@mui/material';
import ProductsServed_2 from '../homeComp/ProductServed_2';

// import 'Slider.css';
function Home() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <Carousel />
      {/* <Slider/> */}
      <div data-aos="fade-up">
        <InfoSection />
      </div>
      <Divider variant="middle" sx={{borderRadius:'0.5rem', borderBottomWidth: 5, borderColor:'#6d6d6d', margin:'0.7rem 1rem'}} />
      <div data-aos="fade-up">
        <ProductsServed title="FIBC Products" />
      </div>
      <Divider variant="middle" sx={{borderRadius:'0.5rem', borderBottomWidth: 5, borderColor:'#6d6d6d', margin:'0.7rem 1rem'}} />
      <div data-aos="fade-up">
        <ProductsServed_2 title="Small Bags, Container Liners & More"/>
      </div>
      <div data-aos="fade-up">
        <WhyKelwa title="Why Kelwa Polymers" />
      </div>
      <div data-aos="fade-up">
        <InHouseProduction title="In House Production"/>
      </div>
      <Divider variant="middle" sx={{borderRadius:'0.5rem', borderBottomWidth: 5, borderColor:'#6d6d6d', margin:'0.7rem 1rem'}} />
    </div>
  )
}

export default Home