import { Box, Typography, Grid } from '@mui/material'
import React from 'react'

function AboutUs() {
  return (
    <div>
      <Box sx={{margin:"1rem"}}>
        <Typography variant='h4' sx={{ mb: '1.2rem', mt: '0.5rem' }}>Know More About Us</Typography>
        <Typography variant="body1">Welcome to Kelwa Polymers, your premier destination for top-quality Polypropylene/PP Woven & Flexible Intermediate Bulk Container (FIBC) Bags. With our steadfast commitment to excellence, we have quickly emerged as a leading Indian manufacturer. Established in 2022 in Mhow, Indore (Madhya Pradesh), we take immense pride in providing superior packaging solutions to the food, agriculture, construction, and chemical industries. Kelwa Polymers is more than just a manufacturer; we are a reliable partner committed to your success.
          <br />
          At Kelwa Polymers, we firmly believe that "Quality Is Our Believe." This tagline encapsulates our unwavering dedication to delivering products of the highest standard. We prioritize quality throughout our manufacturing process, placing great emphasis on the materials we source, the technologies we employ, and the expertise of our team. Our team of experts works closely with you to understand your goals and challenges, offering personalized recommendations and outstanding customer service throughout the process.
          <br />
          {/* <Button><Typography variant="h6" sx={{ textTransform: 'none' }} >Read More</Typography></Button> */}

          We have rigorous quality control procedures guarantee that our bags possess exceptional durability, strength, and resilience, making them ideal for the demanding requirements of the industries we serve. Whether you require packaging for perishable food items, robust agricultural products, heavy construction materials, or sensitive chemicals, Kelwa Polymers has you covered.
          <br />
          At Kelwa Polymers, we take great pride in the trust that our customers place in us. We are dedicated to building long-term relationships based on reliability, transparency, and mutual growth.
          <br />
          Contact Kelwa Polymers today and let us demonstrate our expertise and passion for delivering exceptional Polypropylene/PP Woven & FIBC Bags for your business.
        </Typography>
        {/* <Button onClick={() => openBusinessPresentation()} sx={{ my: '1.5rem' }} variant="contained"  ><Typography variant="h6" sx={{ textTransform: 'none' }}  >Business Presentation</Typography></Button> */}
      </Box>

      <Grid container  sx={{mb:'1rem'}}>
        <Grid item sm={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0rem 1.2rem' }}>
          <Box>

            <Typography variant='h4' sx={{ mb: '1.2rem', mt: '0.5rem' }}>Know More About Us</Typography>
            <Typography variant="body1">Welcome to Kelwa Polymers, your premier destination for top-quality Polypropylene/PP Woven & Flexible Intermediate Bulk Container (FIBC) Bags. With our steadfast commitment to excellence, we have quickly emerged as a leading Indian manufacturer. Established in 2022 in Mhow, Indore (Madhya Pradesh), we take immense pride in providing superior packaging solutions to the food, agriculture, construction, and chemical industries. Kelwa Polymers is more than just a manufacturer; we are a reliable partner committed to your success.
              <br />
              At Kelwa Polymers, we firmly believe that "Quality Is Our Believe." This tagline encapsulates our unwavering dedication to delivering products of the highest standard. We prioritize quality throughout our manufacturing process, placing great emphasis on the materials we source, the technologies we employ, and the expertise of our team. Our team of experts works closely with you to understand your goals and challenges, offering personalized recommendations and outstanding customer service throughout the process.
              <br />
              We have rigorous quality control procedures guarantee that our bags possess exceptional durability, strength, and resilience, making them ideal for the demanding requirements of the industries we serve. Whether you require packaging for perishable food items, robust agricultural products, heavy construction materials, or sensitive chemicals, Kelwa Polymers has you covered.
              <br />
              At Kelwa Polymers, we take great pride in the trust that our customers place in us. We are dedicated to building long-term relationships based on reliability, transparency, and mutual growth.
              <br />
              Contact Kelwa Polymers today and let us demonstrate our expertise and passion for delivering exceptional Polypropylene/PP Woven & FIBC Bags for your business.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'primary.main' }}>
          <img alt="machine" style={{ width: '85%', height: '85%' }} src="https://comsyn.com/wp-content/themes/comsyn/images/ab1.png" />
        </Grid>

      </Grid>
    </div>
  )
}

export default AboutUs