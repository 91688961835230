import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './Components/Navbar';
import Home from './Components/pages/Home';
import AboutUs from './Components/pages/AboutUs';
import Products from './Components/pages/Products';
import Gallery from './Components/pages/Gallery';
import ContactUs from './Components/pages/ContactUs';
import Spinner from './utils/Spinner';
import { useEffect, useState } from 'react';
import Footer from './Components/Footer';

function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [])
  return (
    <>
      {
        loading ? (
          <Spinner />
        ) : (
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/products" element={<Products />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/spinner" element={<Spinner />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        )
      }
    </>
  );
}

export default App;
