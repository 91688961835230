import { Typography, Box } from '@mui/material'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
import { Navigation, Pagination, EffectCoverflow, Autoplay, Scrollbar, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './productServed.css'

import cubical_bulk from '../../assets/cubical_bulk.png'
import tunnel_loop from '../../assets/tunnel_loop.png'
import food_grade from '../../assets/food_grade.png'
import conductive from '../../assets/conductive.png'
import anti_static from '../../assets/anti_static.png'
import un_bulk from '../../assets/un_bulk.png'
import chemical_bulk from '../../assets/chemical_bulk.png'
import loop_bag from '../../assets/loop_bag.png'
import builder_bag from '../../assets/builder_bag.png'
import asbestos_bag from '../../assets/asbestos_bag.png'
import ventilated_bags from '../../assets/ventilated_bags.png'
import u_pannel from '../../assets/u_pannel.png'
import circular_baffle_bag from '../../assets/circular_baffle_bag.png'

const product_list = [
    {
        label: 'Cubical Bulk Bags (FIBC)',
        imgPath: cubical_bulk
    },
    {
        label: 'Tunnel Loop Bag (FIBC)',
        imgPath: tunnel_loop
    },
    {
        label: 'Food Grade FIBC Bag',
        imgPath: food_grade
    },
    {
        label: 'Conductive (Type C FIBC)',
        imgPath: conductive
    },
    {
        label: 'Anti-Static (Type D FIBC)',
        imgPath: anti_static
    },
    {
        label: 'UN Bulk Bag (FIBC)',
        imgPath: un_bulk
    },
    {
        label: 'Chemical Bulk Bag (FIBC)',
        imgPath: chemical_bulk
    },
    {
        label: '1-2 Loop Bag (FIBC)',
        imgPath: loop_bag
    },
    {
        label: 'Builders Bag (FIBC)',
        imgPath: builder_bag
    },
    {
        label: 'Asbestos Bag (FIBC)',
        imgPath: asbestos_bag
    },
    {
        label: 'Ventilated Bags (FIBC)',
        imgPath: ventilated_bags
    },
    {
        label: 'Circular Baffle Bag',
        imgPath: circular_baffle_bag
    },
    {
        label: 'U-Panel Baffle Bag (FIBC)',
        imgPath: u_pannel
    }
]

function ProductsServed(props) {

    return (
        <Box mt='2rem' mb='3rem'>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0rem 1.2rem',textAlign:'center' }}>
                <Typography variant='h4' sx={{ mb: '1.2rem', mt: '0.5rem',color:'primary.main' }}>{props.title}</Typography>
            </Box>
            <Box >
                <Swiper
                    style={{ paddingBottom:'1rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    // loop={true}
                    slidesPerView={'auto'}
                    autoplay={true}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: false,

                    }}
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    navigation
                    modules={[EffectCoverflow, Pagination, Navigation, Autoplay, Scrollbar, A11y]}
                // className="swiper_container"
                >
                    {
                        product_list.map((imageLink, index) => {
                            // console.log(imageLink.imgPath);
                            return(
                            < SwiperSlide >
                                <img src= {imageLink.imgPath} alt={imageLink.label} />
                                <Box sx={{textAlign:'center',paddingBottom:'0.5rem',color:'#284C6D'}}><Typography variant='h5'>{imageLink.label}</Typography></Box>
                            </SwiperSlide>)
                        })
                    }

                    {/* <div className="slider-controler">
                        <div className="swiper-button-prev slider-arrow">
                            <AiOutlineArrowLeft style={{color:'white', fontSize:'1.5rem'}}/>
                        </div>
                        <div className="swiper-button-next slider-arrow">
                            <AiOutlineArrowRight style={{color:'white', fontSize:'1.5rem'}} />
                        </div>
                        <div className="swiper-pagination"></div>
                    </div> */}
                </Swiper>
            </Box>
        </Box >
    )
}

export default ProductsServed