import React from 'react'
import { Box, List, ListItem, Typography, Grid, Divider } from '@mui/material'
import banner_5 from '../../assets/kelwa_certificate.jpg'

function InHouseProduction() {
    return (
        <div>
            <Divider variant="middle" sx={{ borderRadius: '0.5rem', borderBottomWidth: 5, borderColor: '#6d6d6d', margin: '0.7rem 1rem' }} />
            <Box>
                <Grid container >
                    <Grid item sm={12} md={6} style={{ paddingLeft: '1.2rem', paddingRight: '0.5rem', alignItems: 'center', display: 'flex' }}>
                        <Box>
                            <Typography variant='h4' sx={{ mb: '0.5rem', mt: '0.2rem',color:'primary.main' }}>Our In-House Production Facility</Typography>
                            <Typography variant='body1' sx={{textAlign:'justify'}}>
                                Our state-of-the-art facility in Mhow (Indore), India, boasts a formidable 3000 MT per annum capacity for Polypropylene (PP) bags. Equipped with the latest extrusion, weaving, and printing technologies, we ensure unmatched efficiency and reliability. Using only certified, high-grade polypropylene resin, we produce a wide range of standard and custom PP bags for various industries. Our commitment to sustainability includes energy-saving practices and the use of recycled materials whenever possible. Located near key raw material suppliers and transportation hubs, we guarantee timely deliveries and competitive pricing. Contact us today to experience the difference!
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#fff" }}>
                        <Box sx={{display: 'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
                        <Typography variant='h5' sx={{color:'primary.main'}}>ISO 9001 :2015 CERTIFIED</Typography>
                        <img alt="machine" style={{ width: '60%', height: '90%', border: "solid #284C6D 0.2rem", borderRadius: '0.5rem' }} src={banner_5} />
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </div>
    )
}

export default InHouseProduction