import React from 'react';
import { Box, Grid, Typography, TextField, Button } from '@mui/material';

function Footer() {
    return (
        <div style={{ backgroundColor: '#1d2c4d', color: '#fff', padding: '1rem' }}>
            <Box>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={6} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Typography variant='h6'>Registered Office</Typography>
                        <Box sx={{ mt: '0.5rem' }}>
                            <Box sx={{ backgroundColor: 'rgba(191, 191, 191,0.4)', padding: '2rem', borderRadius: '0.5rem' }}>
                                <a href={'https://goo.gl/maps/eWvHCJmRxHqpUCs8A'} target="_blank" style={{ textDecoration: 'none', color: '#fff' }}><Typography variant='body1'>Address: Plot No. 18/1 - 19/1 -39/1, Near Ganesh Petrol Pump, Village - Jamli, Tehsil - Mhow <br />District - Indore(M.P.) - 453441</Typography> </a>
                            </Box>
                            <Box sx={{ backgroundColor: 'rgba(191, 191, 191,0.4)', padding: '2rem', marginTop: '0.5rem', borderRadius: '0.5rem' }}>
                                <a href={'tel:+919302411620'} style={{ textDecoration: 'none', color: '#fff' }}><Typography variant='body1'>Phone: +91 9302411620</Typography></a>
                            </Box>
                            <Box sx={{ backgroundColor: 'rgba(191, 191, 191,0.4)', padding: '2rem', marginTop: '0.5rem', borderRadius: '0.5rem' }}>
                                <a href={'mailto:info@kelwapolymers.com'} style={{ textDecoration: 'none', color: '#fff' }}><Typography variant='body1'>E-mail: info@kelwapolymers.com</Typography></a>
                                <a href={'mailto:sales@kelwapolymers.com'} style={{ textDecoration: 'none', color: '#fff' }}><Typography variant='body1'>E-mail: sales@kelwapolymers.com</Typography></a>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
                        <Typography variant='h6' >Contact Us</Typography>
                        <Box sx={{ backgroundColor: 'rgba(191, 191, 191,0.4)', padding: '0.8rem', mt: '0.5rem', borderRadius: '0.5rem' }}>
                            {/* <TextField sx={{ width: '100%', mt: '0.5rem' }} id="name" label="Name" type='text' required variant="outlined" />
                            <TextField sx={{ width: '100%', mt: '0.5rem' }} id="phone" label="Phone" type='number' required variant="outlined" />
                            <TextField sx={{ width: '100%', mt: '0.5rem' }} id="email" label="E-mail" type='email' required variant="outlined" />
                            <TextField sx={{ width: '100%', mt: '0.5rem' }} id="message" label="Message" type='text' required variant="outlined" />
                            <Button type='submit' value="send" sx={{ width: '100%', mt: '0.5rem' }} variant="contained">Submit</Button> */}
                            <form action='https://formspree.io/f/mqkjeqzw' method='POST'>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder='Enter Your Name'
                                    autoComplete='off'
                                    required
                                    style={{width:'100%',height:'3rem',borderRadius:'0.5rem',padding:'0.5rem'}}
                                />
                                <input
                                    type="number"
                                    name="phone"
                                    placeholder='Enter Your Contact Number'
                                    autoComplete='off'
                                    required
                                    style={{width:'100%',height:'3rem',borderRadius:'0.5rem',marginTop:'0.5rem',padding:'0.5rem'}}
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder='Enter Your E-mail'
                                    autoComplete='off'
                                    required
                                    style={{width:'100%',height:'3rem',borderRadius:'0.5rem',marginTop:'0.5rem',padding:'0.5rem'}}
                                />
                                <textarea
                                    name='Message'
                                    placeholder='Enter Your Message'
                                    cols="30"
                                    rows="6"
                                    autoComplete='off'
                                    required
                                    style={{width:'100%',height:'6rem',borderRadius:'0.5rem',marginTop:'0.5rem', padding:'0.5rem'}}
                                />
                                <input type="submit" value="Submit" style={{width:'100%',height:'3rem',borderRadius:'0.5rem',backgroundColor:'#1D2C4D',color:'#fff',marginTop:'0.3rem',padding:'0.5rem'}} />

                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default Footer