import { Box } from '@mui/material'
import React from 'react'

function ContactUs() {
  return (
    <div>
      <Box sx={{my:'1rem'}}>
      <iframe style={{width:'100%', height:'50vh'}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3685.4032419940136!2d75.6763125!3d22.5265625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962592d15c290db%3A0x715e70d124051067!2sKelwa%20Polymers!5e0!3m2!1sen!2sin!4v1689239793861!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </Box>
    </div>
  )
}

export default ContactUs